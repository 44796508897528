import { Section } from '@bpm-web-app/create-api-sdk';
import { useCreateGenres, useGetFeaturedSoundPackages, useGetNewestSoundPackages, useGetSections, useGetSuggestedSoundPackages } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, DEFAULT_PACKS_LIMIT, useUserSettings } from '@bpm-web-app/utils';
import { useMemo, useState } from 'react';
import { PromotionsMessagingModal } from '@bpm-web-app/components';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import Banner from '../../shared/banner/banner';
import GenresCarousel from '../../genres-carousel/genres-carousel';
import TrendingBrowseCreate from '../../trending/trending-browse-create';
import { GenreSectionComponent } from './create-browse-genre-section-component';
import { NewsCarousel } from '../../shared/news-carousel/news-carousel';
import { AnonymousUserBanner, GhostComponent, MessageBanner } from '../../shared';

export function CreateBrowse() {
    const { data: sectionsData, isLoading: isLoadingSectionData } = useGetSections('browse');
    const { data: newestSoundsData, isLoading: isLoadingNewestSoundsData } = useGetNewestSoundPackages({ limit: DEFAULT_PACKS_LIMIT });

    const { data: featuredSoundsData, isLoading: isLoadingFeaturedSoundsData } = useGetFeaturedSoundPackages({ limit: DEFAULT_PACKS_LIMIT });

    const { data: suggestedSoundPacks, isLoading: isLoadingSuggestedData } = useGetSuggestedSoundPackages();

    const { genres, isLoading: isLoadingGenres } = useCreateGenres();

    const [isBannerDataLoaded, setIsBannerDataLoaded] = useState(false);
    const [isTrendingDataLoaded, setIsTrendingDataLoaded] = useState(false);
    const [isNewsLoaded, setIsNewsLoaded] = useState(false);
    const { isAnonymous } = useUserSettings();

    const isEverythingLoaded = useMemo(() => {
        if (
            !isLoadingSectionData &&
            !isLoadingNewestSoundsData &&
            !isLoadingFeaturedSoundsData &&
            !isLoadingSuggestedData &&
            !isLoadingGenres &&
            isBannerDataLoaded &&
            isTrendingDataLoaded &&
            isNewsLoaded
        ) return true;
        return false;
    }, [
        isBannerDataLoaded,
        isLoadingFeaturedSoundsData,
        isLoadingGenres,
        isLoadingNewestSoundsData,
        isLoadingSectionData,
        isLoadingSuggestedData,
        isNewsLoaded,
        isTrendingDataLoaded
    ]);

    return (
        <div>
            {isAnonymous ? <MessageBanner type="info" title="You’re currently previewing BPM Create." viewPlanRoute /> : null}
            <Banner platform="create" isDataLoaded={isEverythingLoaded} setIsDataLoaded={setIsBannerDataLoaded} />
            <PromotionsMessagingModal />
            {isLoadingSectionData === false ? (
                sectionsData?.data?.map((section) => {
                    if (section.type === Section.TypeEnum.Virtual) {
                        switch (section.name) {
                            case 'Newest Packs':
                                return (
                                    <CreateCardCarousel
                                        contentType="pack"
                                        key={section.id}
                                        items={newestSoundsData?.data || []}
                                        cardSize="small"
                                        carouselMorePath={createAppRoutes.newestPacks}
                                        isLoading={!isEverythingLoaded}
                                        carouselTitle="Newest Sound Packs"
                                    />
                                );
                            case 'Featured Packs': {
                                return (
                                    <CreateCardCarousel
                                        contentType="pack"
                                        key={section.id}
                                        items={featuredSoundsData?.data || []}
                                        cardSize="small"
                                        carouselMorePath={createAppRoutes.featured}
                                        isLoading={!isEverythingLoaded}
                                        carouselTitle="Featured"
                                        linesWidthArrayGhost={[80, 100]}
                                    />
                                );
                            }
                            case 'Trending Packs':
                                return <TrendingBrowseCreate key={section.id} isDataLoaded={isEverythingLoaded} setIsDataLoaded={setIsTrendingDataLoaded} />;
                            case 'Hip Hop':
                                return <GenreSectionComponent slug="Hip Hop & R&B" sectionName={section.name} sectionId={section.id} key={section.id} isLoading={!isEverythingLoaded} />;
                            case 'EDM':
                                return <GenreSectionComponent slug="EDM / Radio Pop" sectionName={section.name} sectionId={section.id} key={section.id} isLoading={!isEverythingLoaded} />;
                            case 'Global':
                                return <GenreSectionComponent slug="Global" sectionName={section.name} sectionId={section.id} key={section.id} isLoading={!isEverythingLoaded} />;
                            case 'Genres':
                                return <GenresCarousel genres={genres} key={section.id} isLoading={!isEverythingLoaded} />;
                            case 'For You': {
                                return (
                                    <CreateCardCarousel
                                        contentType="pack"
                                        key={section.id}
                                        items={suggestedSoundPacks?.data || []}
                                        cardSize="small"
                                        carouselMorePath={createAppRoutes.suggestions}
                                        isLoading={!isEverythingLoaded}
                                        carouselTitle="For You"
                                    />
                                );
                            }
                            default:
                                return null;
                        }
                    } else {
                        // eslint-disable-next-line no-underscore-dangle
                        const type = (section.items?.[0] as any)?.__typename;
                        if (!type) {
                            return null;
                        }
                        return (
                            <CreateCardCarousel
                                contentType={type === 'Curated' ? 'curated-set' : 'pack'}
                                key={section.id}
                                items={section.items}
                                cardSize="small"
                                carouselTitle={section.name}
                                isLoading={!isEverythingLoaded}
                                carouselMorePath={createAppRoutes.section(section.name, 'browse', type === 'Curated' ? 'curated' : 'soundPackage')}
                            />
                        );
                    }
                })
            ) : (
                <>
                    <GhostComponent type="cards" cardSize="small" title="Newest Sound Packs" elementsCount={20} lineHeight={19} isCreate />
                    <GhostComponent type="cards" cardSize="small" title="Featured" elementsCount={20} lineHeight={19} isCreate />
                </>
            )}
            <AnonymousUserBanner isCreate />

            {/* eslint-disable-next-line @typescript-eslint/dot-notation */}
            <NewsCarousel newsProviderId={`${process.env['NEXT_PUBLIC_MAIN_BPM_NEWS_PROVIDER_ID']}`} isCreate isDataLoaded={isEverythingLoaded} setIsDataLoaded={setIsNewsLoaded} />
        </div>
    );
}
